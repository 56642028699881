import { useEffect, useMemo, useState, useCallback } from "react";
import {
  Button,
  Dialog,
  Flex,
  Popup,
  Table,
  TrashCanIcon,
  Image,
  Text,
  Alert,
} from "@fluentui/react-northstar";
import "../index.css";
import "./Dashboard.css";
import { client } from "../utils/client";
import { Formik } from "formik";
import ConnectorsForm from "./organisms/ConnectorsForm";
import { openAlert } from "../reducers/uiSlice";
import { useDispatch } from "react-redux";
import HeaderTab from "./atoms/HeaderTab";
import { useTeamsFx } from "./sample/lib/useTeamsFx";
import { useData } from "./sample/lib/useData";
import { TeamsUserCredential } from "@microsoft/teamsfx";

let platforms: any[] = [];

const Dashboard = () => {
  const { isInTeams } = useTeamsFx();
  const [existMicrosoftTeamsConnector, setMsConnector] = useState(true);

  const dispatch = useDispatch();

  let token: any;
  const [existConnectors, setExistConnectors] = useState(false);
  token = localStorage.getItem("activation_token")
    ? localStorage.getItem("activation_token")
    : null;
  let [platforms_to_show, setPlatforms] = useState<any>([]);
  const [selectedPlatform, setSelectedPlatform] = useState<any>();
  const [connectors, setConnectors] = useState<any>([]);
  let [disabledAppsCreation, setDisabledAppsCreation] = useState(false);
  // const [platformMicrosoftTeams, setPlatformMSTeams] = useState<any>();
  let platformMicrosoftTeams: any = null;
  let ecommercePlatforms: any = [];
  let erpPlatforms: any = [];
  let shippingPlatforms: any = [];

  let [existEcommerce, setExistEcommerce] = useState(0);
  let [existErp, setExistErp] = useState(0);
  let [existShipping, setExistShipping] = useState(0);

  const credential = new TeamsUserCredential();
  const userProfile = useData(async () => {
    const credentialUser = isInTeams
      ? await credential.getUserInfo()
      : undefined;

    return credentialUser;
  })?.data;
  const userName = userProfile ? userProfile.displayName : "";
  if (localStorage.getItem("userName")) {
    localStorage.removeItem("userName");
    localStorage.setItem("userName", userName);
  } else {
    localStorage.setItem("userName", userName);
  }

  const header = {
    key: "header",
    items: [
      {
        content: "Nome",
        key: "name",
      },
      {
        content: "Data creazione",
        key: "date-created",
      },
      {
        key: "more options",
        "aria-label": "options",
      },
    ],
  };
  const deleteConnector = (id: any, name: string) => {
    try {
      client.delete("api/teams/v1/connector/" + id).then((response) => {
        if (response.data.data === true) {
          console.log(connectors);
          getAndSetConnectors();
        }
      });
    } catch (e) {}
  };

  async function getTokenSSO() {
    try {
      let login = await credential.login(
        "email profile Channel.ReadBasic.All Files.ReadWrite.AppFolder Files.ReadWrite.All Files.Read.All User.Read Team.ReadBasic.All Sites.Read.All openid offline_access Contacts.ReadWrite"
      );
      const response_token = isInTeams
        ? await credential.getToken("")
        : undefined;
      if (response_token && !existMicrosoftTeamsConnector) {
        if (localStorage.getItem("tokenUser")) {
          localStorage.removeItem("tokenUser");
          localStorage.setItem("tokenUser", response_token.token);
        } else {
          localStorage.setItem("tokenUser", response_token.token);
        }
      }
      return response_token;
    } catch (error) {
      console.log(error);
    }
  }
  function exchangeToken() {
    if (platforms.length > 0) {
      const existTeamsConnector = platforms.some(
        (item: { name: string }) => item.name === "Microsoft 365"
      );
      setMsConnector(existTeamsConnector);
    } else if (platforms.length === 0) {
      setMsConnector(false);
    }
  }
  async function createTeamsConnector(response: any) {
    let data = response.data.data;
    let connectorMicrosoftTeams = {
      name: "Microsoft 365",
      api_url: "https://graph.microsoft.com",
      tenant_id: "d0ec715e-eb09-409f-a54f-57c525d10667",
      access_token: data.access_token,
      refresh_token: data.refresh_token,
      platforms_id: platformMicrosoftTeams._id,
    };
    const responseConnectorTeams = await client.post(
      "api/teams/v1/connector",
      connectorMicrosoftTeams
    );
    return responseConnectorTeams;
  }
  useEffect(() => {
    if (!existMicrosoftTeamsConnector) {
      getTokenSSO().then(() => {
        const graphScopes = [
          "https://graph.microsoft.com/.default offline_access",
        ];
        let requestParams = {
          grant_type: "urn:ietf:params:oauth:grant-type:jwt-bearer",
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
          scope: graphScopes,
          requested_token_use: "on_behalf_of",
          assertion: localStorage.getItem("tokenUser"),
        };

        try {
          client
            .post("api/teams/v1/gentokenproxy", requestParams)
            .then(async (responseAccessRefresh) => {
              if (
                !platformMicrosoftTeams ||
                platformMicrosoftTeams === "undefined"
              ) {
                getCollaboratioPlatform().then((response) => {
                  createTeamsConnector(responseAccessRefresh).then(
                    (responseConnectorTeams: any) => {
                      if (responseConnectorTeams.data.access) {
                        getAndSetConnectors();
                      }
                    }
                  );
                });
              } else {
                createTeamsConnector(responseAccessRefresh).then(
                  (responseConnectorTeams: any) => {
                    if (responseConnectorTeams.data.access) {
                      getAndSetConnectors();
                    }
                  }
                );
              }
            });
        } catch (e) {
          console.log(e);
        }
      });
    }
  }, [existMicrosoftTeamsConnector]);
  useEffect(() => {
    let connectorsTable: { key: number; items: any[] }[] = [];
    platforms.forEach((element, index) => {
      let plat = platforms_to_show.find(function (obj: any) {
        return obj["_id"] === element.platforms_id;
      });
      let img = null;
      if (plat) {
        img = plat.img_btn ? plat.img_btn : null;
      }
      let deleteIcon: any = " ";
      if (element.name != "Microsoft 365") {
        deleteIcon = (
          <Dialog
            cancelButton="Annulla"
            confirmButton="Conferma"
            onConfirm={() => deleteConnector(element._id, element.name)}
            content="Cancellando questa app, verranno automaticamente rimossi tutti i workflow associati. Vuoi procedere?"
            header="Conferma"
            trigger={<Button icon={<TrashCanIcon />} text iconOnly />}
          />
        );
      }
      connectorsTable.push({
        key: index + element.name,
        items: [
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={process.env.REACT_APP_API_URL + "/" + img}
                style={{
                  height: "35px",
                  width: "35px",
                  borderRadius: "3px",
                  marginRight: "5px",
                }}
              ></img>
              {element.name}
            </div>
          </>,
          element.created_at,
          deleteIcon,
        ],
      });
    });
    setConnectors(connectorsTable);
  }, [platforms_to_show]);
  useEffect(() => {
    client
      .get("/api/teams/v1/connector")
      .then((response: any) => {
        platforms = response.data.data.platforms;
        getCollaboratioPlatform().then(() => {
          getErpPlatform().then(() => {
            getEcommercePlatform().then(() => {
              getShippingPlatform().then(() => {
                checkErp();
                checkEcommerce();
                checkShipping();
                getPlatforms().then(() => {
                  if (platforms.length > 0) {
                    exchangeToken();
                    setExistConnectors(true);
                  } else {
                    exchangeToken();
                    setExistConnectors(false);
                  }
                });
              });
            });
          });
        });
      })
      .catch(function (error: any) {
        console.log(error);
      });
  }, []);
  function checkEcommerce() {
    let ecommerce = 0;
    platforms.forEach((element) => {
      const ecommerceCheck = ecommercePlatforms.find((ecommerce: any) => {
        return ecommerce._id === element.platforms_id;
      });
      if (ecommerceCheck) {
        ecommerce++;
      }
    });
    setExistEcommerce(ecommerce);
  }
  function checkShipping() {
    let shipping = 0;
    platforms.forEach((element) => {
      const shippingCheck = shippingPlatforms.find((ship: any) => {
        return ship._id === element.platforms_id;
      });
      if (shippingCheck) {
        shipping++;
      }
    });
    setExistShipping(shipping);
  }
  function checkErp() {
    let erp = 0;
    platforms.forEach((element) => {
      const erpCheck = erpPlatforms.find((erp: any) => {
        return erp._id === element.platforms_id;
      });
      if (erpCheck) {
        erp++;
      }
    });
    setExistErp(erp);
  }
  useEffect(() => {
    if (existErp === 1 && existEcommerce === 2 && existShipping === 1) {
      setDisabledAppsCreation(true);
    } else {
      setDisabledAppsCreation(false);
    }
  }, [existErp, existEcommerce, existShipping]);
  async function getPlatforms() {
    client
      .get("api/teams/v1/platforms")
      .then((response: any) => {
        setPlatforms(response.data.data);
      })
      .catch(function (error: any) {
        console.log(error);
      });
  }

  async function getCollaboratioPlatform() {
    try {
      const response = await client.get("api/teams/v1/platform/collaboration");

      const msTeams = response.data.data.find((element: any) => {
        return element.name === "microsoft365";
      });
      platformMicrosoftTeams = msTeams;
    } catch (e) {
      console.log(e);
    }
  }
  async function getShippingPlatform() {
    try {
      const response = await client.get("api/teams/v1/platform/shipping");
      shippingPlatforms = response.data.data;
    } catch (e) {
      console.log(e);
    }
  }
  async function getEcommercePlatform() {
    try {
      const response = await client.get("api/teams/v1/platform/ecommerce");
      ecommercePlatforms = response.data.data;
    } catch (e) {
      console.log(e);
    }
  }
  async function getErpPlatform() {
    try {
      const response = await client.get("api/teams/v1/platform/erp");
      erpPlatforms = response.data.data;
    } catch (e) {
      console.log(e);
    }
  }

  const initialValues = useMemo(() => {
    let fields: Record<string, string> = {
      name: "",
      api_url: "",
      platforms_id: selectedPlatform ? selectedPlatform._id : null,
    };

    if (selectedPlatform?.variables?.length) {
      selectedPlatform.variables.forEach((field: string) => {
        fields[field] = "";
      });
    }

    if (selectedPlatform?.variables_disabled?.length) {
      selectedPlatform.variables_disabled.forEach((field: string) => {
        fields[field] = "";
      });
    }

    return fields;
  }, [selectedPlatform]);
  function getAndSetConnectors() {
    client.get("/api/teams/v1/connector").then((response: any) => {
      platforms = response.data.data.platforms;

      if (platforms.length > 0) {
        let connectorsTable: { key: number; items: any[] }[] = [];
        platforms.forEach((element, index) => {
          let deleteIcon: any = " ";
          if (element.name != "Microsoft 365") {
            deleteIcon = (
              <Dialog
                cancelButton="Annulla"
                confirmButton="Conferma"
                onConfirm={() => deleteConnector(element._id, element.name)}
                content="Cancellando questa app, verranno automaticamente rimossi tutti i workflow associati. Vuoi procedere?"
                header="Conferma"
                trigger={<Button icon={<TrashCanIcon />} text iconOnly />}
              />
            );
          }
          connectorsTable.push({
            key: index + element.name,
            items: [element.name, element.created_at, deleteIcon],
          });
        });

        setExistConnectors(true);
        setConnectors(connectorsTable);
      }
    });
  }

  const onSubmit = useCallback(
    async (values: any, { resetForm }) => {
      try {
        const response = await client.post("api/teams/v1/connector", values);
        if (response.data.access) {
          getAndSetConnectors();
          checkEcommerce();
          checkErp();
          checkShipping();
          resetForm();
        }
        dispatch(
          openAlert({
            content: "Salvataggio effettuato con successo",
            type: "success",
          })
        );
      } catch (e) {
        // e.getmessage
        dispatch(
          openAlert({
            content: "Errore salvataggio",
            type: "danger",
            keepOpen: true,
          })
        );
      }
    },
    [dispatch]
  );

  return (
    <>
      <HeaderTab></HeaderTab>
      {!platformMicrosoftTeams ||
        (platformMicrosoftTeams === "undefined" && (
          <div>
            <Alert
              content={
                <Flex gap="gap.medium" padding="padding.medium">
                  <Flex.Item size="3/4">
                    <Text
                      styles={{ marginLeft: "80px" }}
                      content="Attenzione:sblocca il popup e abilita i permessi richiesti, così creeremo il tuo connettore a Microsoft 365"
                    />
                  </Flex.Item>

                  <Flex.Item size="size.medium">
                    <Image
                      styles={{ width: "200px" }}
                      src={process.env.PUBLIC_URL + "/" + "TEAMSconnettore.png"}
                    />
                  </Flex.Item>
                </Flex>
              }
              visible
            />
          </div>
        ))}
      <div className="ms-Grid-row">
        <div
          className="ms-Grid-col ms-sm12 ms-md12 ms-lg9 ms-lgoffset1 ms-xs12"
          style={{ marginTop: "20px" }}
        >
          <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 ms-xs12">
            <h1>Le tue app</h1>
          </div>

          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            <ConnectorsForm
              selectedPlatform={selectedPlatform}
              setSelectedPlatform={setSelectedPlatform}
              platforms_to_show={platforms_to_show}
              disabledWfCreation={disabledAppsCreation}
            />
          </Formik>
        </div>
      </div>

      {existConnectors && (
        <div className="ms-Grid-row">
          <div
            className="ms-Grid-col ms-sm12 ms-md12 ms-lg9 ms-lgoffset1 ms-xs12"
            style={{ marginTop: "20px" }}
          >
            <p>
              Semplisio collega ecommerce, gestionali e Microsoft 365 per
              automatizzare i processi. Connetti le tue applicazioni per gestire
              il lavoro all’interno di Microsoft Teams. Il tuo account Microsoft
              365 è già collegato.
            </p>

            <Table
              header={header}
              rows={connectors}
              aria-label="Nested navigation"
              // accessibility={gridNestedBehavior}
            />
          </div>
        </div>
      )}
      {!existConnectors && (
        <div className="ms-Grid-row">
          <div
            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xs12"
            style={{ textAlign: "center" }}
          >
            <embed
              src={process.env.PUBLIC_URL + "/img_no_connectors.svg"}
            ></embed>
          </div>
          <div
            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xs12 colorBlue"
            style={{ textAlign: "center" }}
          >
            <h3 className="colorBlue">Non hai ancora collegato un'app</h3>
          </div>
        </div>
      )}
      <div className="ms-Grid-row">
        <div
          className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xs12"
          style={{ marginTop: "60px", textAlign: "center" }}
        >
          <span>Dopo aver connesso le app attiva i workflow.</span>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
