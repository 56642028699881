import { Provider, teamsTheme, Loader } from "@fluentui/react-northstar";
import { HashRouter as Router, Redirect, Route } from "react-router-dom";
import { useTeamsFx } from "./sample/lib/useTeamsFx";
import Tab from "./Tab";
import "./App.css";
import useAxiosInterceptor from "./hooks/useAxiosInterceptor";
import LoadingModal from "./organisms/LoadingModal";
import Workflow from "./Workflow";
import { Provider as ReduxProvider } from "react-redux";
import store from "../store/store";
import AlertResponse from "./organisms/AlertResponse";
import TabConfig from "./TabConfig";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const { theme, loading: teamsLoading } = useTeamsFx();
  const { loading, configReady } = useAxiosInterceptor();

  if (!configReady) {
    return null;
  }

  return (
    <ReduxProvider store={store}>
      <Provider
        theme={theme || teamsTheme}
        styles={{ backgroundColor: "none" }}
      >
        <LoadingModal loading={!!loading} />
        <AlertResponse />

        <Router>
          <Route exact path="/">
            <Redirect to="/tab" />
          </Route>
          {teamsLoading ? (
            <Loader style={{ margin: 100 }} />
          ) : (
            <>
              <Route exact path="/tab" component={Tab} />
              <Route exact path="/workflows" component={Workflow} />
              <Route exact path="/config" component={TabConfig} />
            </>
          )}
        </Router>
      </Provider>
    </ReduxProvider>
  );
}
