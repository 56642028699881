import { Button, Flex, Form, PopupIcon } from "@fluentui/react-northstar";
import "../index.css";
import { ChangeEvent, FunctionComponent } from "react";
import SelectIcons from "./SelectIcons";
import FormInput from "./atoms/FormInput";

interface Props {
  platforms: any[];
  setSelectedPlatform: React.Dispatch<any>;
  selectedPlatform: any;
  onVerify: (values: any) => void;
}

const Connectors: FunctionComponent<Props> = ({
  platforms,
  setSelectedPlatform,
  selectedPlatform,
  onVerify,
}) => {
  const platformSelected = (event: ChangeEvent<HTMLInputElement>): any => {
    document
      .getElementById("connectors")
      ?.scrollTo({ top: 0, behavior: "smooth" });
    document
      .getElementById("dialog-content-1")
      ?.scrollTo({ top: 0, behavior: "smooth" });

    if (platforms) {
      let platform = platforms.find(function (obj: any) {
        return obj["_id"] === event.target.value;
      });
      setSelectedPlatform(platform);
    }
  };
  function getInputField(inputName: string) {
    let inputType;
    switch (true) {
      case inputName.toUpperCase().indexOf("password".toUpperCase()) !== -1:
        inputType = "password";
        break;
      case inputName.toUpperCase().indexOf("secret".toUpperCase()) !== -1:
        inputType = "password";
        break;
      default:
        inputType = "text";
        break;
    }
    return inputType;
  }

  return (
    <div>
      <Flex gap="gap.small" padding="padding.medium" id="modal" className="">
        <Flex.Item size="size.half" align="center">
          <div>
            <img
              className="mobileWidth80"
              alt="logo"
              style={{ fontWeight: "50%", width: "200px" }}
              src={process.env.PUBLIC_URL + "/logo_registrato.png"}
            />
            <Flex.Item styles={{ textAlign: "center" }}>
              <>
                <h4>
                  Scegli l'app che vuoi collegare.<br></br> Semplisio farà il
                  resto
                </h4>
              </>
            </Flex.Item>
            <Flex
              gap="gap.small"
              styles={{ flexWrap: "wrap", textAlign: "center" }}
              padding="padding.medium"
            >
              {platforms.map((plat: any) => {
                return (
                  <Flex.Item size="size.half">
                    <SelectIcons
                      key={plat._id}
                      platform={plat}
                      onChange={platformSelected}
                    />
                  </Flex.Item>
                );
              })}
            </Flex>
          </div>
        </Flex.Item>
        <Flex.Item size="size.half">
          <Flex column>
            <Flex.Item>
              <>
                <h2>Compila il form per connettere la tua app</h2>
                <span>
                  Consulta le{" "}
                  <a
                    href="https://www.semplisio.it/guide/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    GUIDE <PopupIcon></PopupIcon>
                  </a>{" "}
                  per creare i connettori
                </span>
              </>
            </Flex.Item>
            <Flex.Item>
              <Form styles={{ height: "auto" }}>
                <FormInput label="Name" name="name" />
                <FormInput label="URL" name="api_url" />

                {selectedPlatform?.variables?.map(
                  (field: string, idx: number) => (
                    <>
                      {!selectedPlatform?.variables_disabled?.includes(
                        field
                      ) && (
                        <FormInput
                          key={field + idx}
                          label={field}
                          name={field}
                          type={getInputField(field)}
                          disabled={selectedPlatform?.variables_disabled?.includes(
                            field
                          )}
                        />
                      )}
                    </>
                  )
                )}
                {/* 
                {selectedPlatform?.variables_disabled?.map(
                  (field: string, idx: number) => (
                    <FormInput
                      key={field + idx}
                      label={field}
                      name={field}
                      disabled
                    />
                  )
                )} */}

                <Button size="small" onClick={onVerify} type="button">
                  Verifica connessione
                </Button>
              </Form>
            </Flex.Item>
          </Flex>
        </Flex.Item>
      </Flex>
    </div>
  );
};

export default Connectors;
