import { Flex, Input, Label } from "@fluentui/react-northstar";
import { env } from "process";
import React, {
  ChangeEvent,
  Component,
  FunctionComponent,
  MouseEventHandler,
  useMemo,
  useState,
} from "react";
import "./SelectIcons.css";
import { client } from "../utils/client";
import { platform } from "os";
import connectorsConfig from "../connectorsConfig.json";
let namePlatforms: any = { ...connectorsConfig };

interface Props {
  platform: any;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const SelectIcons: FunctionComponent<Props> = ({ platform, onChange }) => {
  return (
    <>
      <input
        className="hidden"
        id={"module_" + platform._id}
        type="radio"
        name="test"
        value={platform._id}
        onChange={onChange}
      ></input>
      <label
        htmlFor={"module_" + platform._id}
        style={{ textAlign: "center", width: "30%", paddingBottom: "10px" }}
        className="mobileWidth80"
      >
        <div className="responsive-rectangle">
          <div className="image-wrapper">
            <img
              src={process.env.REACT_APP_API_URL + "/" + platform.img_btn}
              alt=""
            ></img>
          </div>
        </div>

        <label
          style={{
            width: "119px",
            textAlign: "center",
            marginBottom: "10px",
            display: "block",
            textTransform: "capitalize",
          }}
        >
          {namePlatforms[platform.name]
            ? namePlatforms[platform.name]
            : platform.name}
        </label>
      </label>
    </>
  );
};

export default SelectIcons;
