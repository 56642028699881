import React, { FunctionComponent } from "react";
import { FormInput as FormInputUI } from "@fluentui/react-northstar";
import { useField } from "formik";

const FormInput: FunctionComponent<any> = (props) => {
  const [field, meta, helpers] = useField(props.name);

  return <FormInputUI {...props} {...field} />;
};

export default FormInput;
