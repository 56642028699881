import React from "react";
import { Welcome } from "./sample/Welcome";
import { useState } from "react";
import Login from "./Login";
import Dashboard from "./Dashboard";

var showFunction = Boolean(process.env.REACT_APP_FUNC_NAME);

export default function Tab() {
  const [authentication, setAuthentication] = useState(
    localStorage.getItem("activation_token") ? true : false
  );

  if (!authentication) {
    return (
      <Login
        onLoginSuccess={() => {
          setAuthentication(true);
        }}
      />
    );
  }

  return <Dashboard />;
}
