import {
  Alert,
  Button,
  Card,
  Checkbox,
  Dropdown,
  Flex,
  FlexItem,
  getStartDateOfWeek,
  InfoIcon,
  Input,
  Table,
  TeamsMonochromeIcon,
  Text,
} from "@fluentui/react-northstar";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { client } from "../utils/client";
import HeaderTab from "./atoms/HeaderTab";
import querystring from "querystring";

import * as microsoftTeams from "@microsoft/teams-js";
import axios from "axios";
import { Field, Formik, Form, useFormik } from "formik";
import workflowConfig from "../workflowConfig.json";
import "../index.css";

import Login from "./Login";

import { useTeamsFx } from "./sample/lib/useTeamsFx";
// import { values } from "@uifabric/utilities";

const msal = require("@azure/msal-node");

const Workflow = () => {
  const [authentication, setAuthentication] = useState(
    localStorage.getItem("activation_token") ? true : false
  );
  let [templateWf, setTemplateWf] = useState<any>([]);
  //let [startAndOut, setStartAndOut] = useState<any>(false);
  let startAndOut = false;
  let platformMicrosoftTeams;
  // const [platformMicrosoftTeams, setPlatformMSTeams] = useState<any>();
  let [connectors, setConnectors] = useState<any>([]);
  let [teamsPlat, setTeamsPlat] = useState<any>();

  let [teams, setTeams] = useState<any>([]);
  let [channels, setChannels] = useState<any>([]);
  let [platforms_start_id, setPlatformStartId] = useState<any>([]);
  let [platforms_to_id, setPlatformToId] = useState<any>([]);
  let ecommercePlatforms: any = [];
  let erpPlatforms: any = [];
  let shippingPlatforms: any = [];
  let [ecommerceConn, setEcommerce] = useState<any>([]);
  let [erpConn, setErp] = useState<any>([]);
  let [shippingConn, setShipping] = useState<any>([]);
  let ecommerceConnectors: any = [];
  let shippingConnectors: any = [];
  let erpConnectors: any = [];

  let initialValues: any = [];
  let loadData: any = { ...workflowConfig };
  const { isInTeams } = useTeamsFx();
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => { },
  });
  let rows: any = [];
  const header = {
    key: "header",
    items: [
      {
        content: "Automazione",
        key: "automazione",
      },
      {
        content: "Da",
        key: "da",
      },
      {
        content: "A",
        key: "a",
      },
      {
        content: "Data creazione",
        key: "date-created",
      },
      {
        key: "more options",
        "aria-label": "options",
      },
    ],
  };
  const isMounted = useRef(true);
  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  useEffect(() => {
    try {
      if (authentication) {
        getWfTemplates();
        getCollaboratioPlatform();
      }
    } catch (e) {
      console.log(e);
    }
    return () => {
      isMounted.current = false;
    };
  }, [authentication]);
  function setShippingConnectors() {
    let shippingCheck;
    connectors.forEach((element: any) => {
      shippingCheck = shippingPlatforms.find((ship: any) => {
        return ship._id === element.platforms_id;
      });
      if (shippingCheck) {
        shippingConnectors.push(element);
      }
    });
    if (isMounted) setShipping(shippingConnectors);
  }
  function setEcommerceConnectors() {
    let ecommerceCheck;
    connectors.forEach((element: any) => {
      ecommerceCheck = ecommercePlatforms.find((ecommerce: any) => {
        return ecommerce._id === element.platforms_id;
      });
      if (ecommerceCheck) {
        ecommerceConnectors.push(element);
      }
    });
    if (isMounted) setEcommerce(ecommerceConnectors);
  }

  function setErpConnectors() {
    let erpCheck;
    connectors.forEach((element: any) => {
      erpCheck = erpPlatforms.find((erp: any) => {
        return erp._id === element.platforms_id;
      });
      if (erpCheck) {
        erpConnectors.push(element);
      }
    });
    if (isMounted) setErp(erpConnectors);
  }
  async function getEcommercePlatform() {
    try {
      const response = await client.get("api/teams/v1/platform/ecommerce");
      ecommercePlatforms = response.data.data;
    } catch (e) {
      console.log(e);
    }
  }
  async function getShippingPlatform() {
    try {
      const response = await client.get("api/teams/v1/platform/shipping");
      shippingPlatforms = response.data.data;
    } catch (e) {
      console.log(e);
    }
  }
  async function getErpPlatform() {
    try {
      const response = await client.get("api/teams/v1/platform/erp");
      erpPlatforms = response.data.data;
    } catch (e) {
      console.log(e);
    }
  }
  function getWfTemplates() {
    client.get("api/teams/v1/workflow/type/teams").then((response: any) => {
      if (isMounted) setTemplateWf(response.data.data);

      //read platform settings
      getConnectors();
    });
  }
  useEffect(() => {
    let platforms_to_id_to_show: any[] = [];
    let platforms_start_id_to_show: any[] = [];
    templateWf.forEach((workflow: any) => {
      platforms_start_id_to_show[workflow._id] = [];
      let platforms_id_start = workflow.platforms_input_id;
      let platforms_id_to = workflow.platforms_out_id;
      platforms_id_start.forEach((plat_id: any) => {
        connectors.find((element: any) => {
          if (element.platforms_id === plat_id) {
            let conn_to_add = {
              key: element._id,
              label: element.name,
              header: element.name,
            };

            platforms_start_id_to_show[workflow._id].push(conn_to_add);

            if (isMounted)
              setPlatformStartId((prevState: any) => ({
                ...prevState,
                ...platforms_start_id_to_show,
              }));
          }
        });
      });
      // if (isMounted) setPlatformStartId(platforms_start_id_to_show);
      platforms_id_to.forEach((plat_id: any) => {
        connectors.find((element: any) => {
          if (element.platforms_id === plat_id) {
            let conn_to_add = {
              key: element._id,
              label: element.name,
              header: element.name,
            };
            platforms_to_id_to_show[workflow._id] = [conn_to_add];
            if (isMounted)
              setPlatformToId((prevState: any) => ({
                ...prevState,
                ...platforms_to_id_to_show,
              }));
          }
        });
      });
    });
    return () => {
      isMounted.current = false;
    };
  }, [templateWf, connectors]);
  useEffect(() => {
    getEcommercePlatform().then(() => {
      getErpPlatform().then(() => {
        getShippingPlatform().then(() => {
          if (isMounted) {
            setErpConnectors();
            setEcommerceConnectors();
            setShippingConnectors();
          }
        });
      });
    });
    return () => {
      isMounted.current = false;
    };
  }, [connectors]);
  function getTeamsChannels() {
    if (teamsPlat) {
      const json = {
        connector_id: teamsPlat._id,
        module: ["teams"],
      };
      client.post("api/teams/v1/getteams", json).then((response: any) => {
        let teams = response?.data.data.teams;
        let teams_to_show: any = [];
        if (teams?.length > 0) {
          teams.forEach((element: any) => {
            teams_to_show.push({
              key: element.id,
              label: element.name,
              header: element.name,
            });
          });
          if (isMounted) setTeams(teams_to_show);
        }
        templateWf.forEach((workflow: any) => {
          if (workflow.workflow) {
            //SONO IN PUT
            if (workflow.actions_templates[0].select_channels) {
              getChannels(
                teamsPlat._id,
                workflow.workflow[0].actions_checked[0].teams
              );
            }
          }
        });
      });
    }
  }

  useEffect(() => {
    let teamsPlatTemp = connectors.find((element: any) => {
      return element.name === "Microsoft 365";
    });
    // check if connector teams exists and set teamsPlat variable
    if (isMounted) setTeamsPlat(teamsPlatTemp);
    return () => {
      isMounted.current = false;
    };
    // setTeamsPlat(teamsPlat);
  }, [connectors]);
  useEffect(() => {
    getTeamsChannels();
    return () => {
      isMounted.current = false;
    };
  }, [teamsPlat]);

  async function getConnectors() {
    try {
      const response = client.get("/api/teams/v1/connector");
      if (isMounted) setConnectors((await response).data.data.platforms);
    } catch (e) {
      console.log(e);
    }
  }
  function onChangeHandler(event: any, data: any) {
    //Need to get the selected value from dropdown
    let selectedTeamId = data.value.key;
    getChannels(teamsPlat._id, selectedTeamId);
  }
  async function getChannels(teamsConnectorId: any, idTeam: any) {
    let channelsPush: any = [];

    const channelResponse = await client.get(
      "/api/teams/v1/channels/ms_teams/" +
      teamsConnectorId +
      "/channels/" +
      idTeam
    );
    let channels_get = channelResponse.data.data;
    let channels_to_show: any = [];

    if (channels_get && channels_get.length > 0) {
      channels_get.forEach((element: any) => {
        channels_to_show.push({
          key: element.id,
          label: element.displayName,
          header: element.displayName,
        });
      });

      channelsPush[idTeam] = channels_to_show;
      if (isMounted)
        setChannels((prevState: any) => ({
          ...prevState,
          ...channelsPush,
        }));

      // setChannels(newChannels);
      // channels[idTeam] = [];
      // channels[idTeam] = channels_to_show;
    }
  }

  function setFieldsWf(valuesForm: any, wfTemplate: any) {
    let actions_checked = wfTemplate.actions_templates[0];
    let objPlatSettings;
    if (wfTemplate.show_select_to) {
      objPlatSettings = {
        platforms_settings_id: valuesForm.platforms_settings_id,
      };
    } else {
      objPlatSettings = { platforms_settings_id: teamsPlat._id };
    }

    let new_action = { ...actions_checked, ...objPlatSettings };
    if (wfTemplate.actions_templates[0].select_channels) {
      new_action = { ...new_action, ...{ channel: valuesForm.channel } };
    }
    if (wfTemplate.actions_templates[0].select_teams) {
      new_action = { ...new_action, ...{ teams: valuesForm.teams } };
    }
    if (wfTemplate.actions_templates[0].action_card) {
      let action_card = wfTemplate.actions_templates[0].action_card[0];
      if (action_card) {
        if (action_card.show_actions_teams_channel_orders) {
          action_card = {
            ...action_card,
            ...{
              show_actions_teams_channel: valuesForm.show_actions_teams_channel,
            },
          };
        }
      }
      new_action.action_card = [action_card];
    }
    new_action.module_id = new_action.module_id.$oid;

    let jsonWf = {
      name: wfTemplate.key_name,
      description: wfTemplate.key_description,
      enabled: valuesForm.enabled,
      platform_start_id: valuesForm.platform_start_id,
      activation_id: "",
      wf_start_id: wfTemplate.wf_start_id,
      condictions: [],
      webhook_semplisio: wfTemplate.webhook_semplisio,
      actions_checked: [new_action],
      actions_not_checked: [],
      sequential: false,
      wf_template_id: wfTemplate._id,
    };

    return jsonWf;
  }
  async function putWorkflow(
    values: any,
    wf: any,
    indexEcommerce: number,
    idChannel?: any,
    showActionsTeamsChannel?: any
  ) {
    let jsonWf = wf.workflow[indexEcommerce];
    jsonWf.enabled = values.enabled;
    jsonWf.platform_start_id = values.platform_start_id;

    if (wf.actions_templates[0].select_channels) {
      jsonWf.actions_checked[0].channel = values.channel;
    }
    if (idChannel && idChannel !== null) {
      jsonWf.actions_checked[0].channel = idChannel;
    }
    if (wf.actions_templates[0].select_teams) {
      jsonWf.actions_checked[0].teams = values.teams;
    }

    if (wf.actions_templates[0].action_card) {
      let action_card = jsonWf.actions_checked[0].action_card[0];
      if (action_card) {
        if (action_card.show_actions_teams_channel_orders) {
          action_card.show_actions_teams_channel =
            values.show_actions_teams_channel;
          if (showActionsTeamsChannel) {
            action_card.show_actions_teams_channel = showActionsTeamsChannel;
          }
        }
      }
    }

    jsonWf.actions_checked[0].module_id = jsonWf.actions_checked[0].module_id
      .$oid
      ? jsonWf.actions_checked[0].module_id.$oid
      : jsonWf.actions_checked[0].module_id;
    jsonWf.actions_checked[0].platforms_settings_id = jsonWf.actions_checked[0]
      .platforms_settings_id.$oid
      ? jsonWf.actions_checked[0].platforms_settings_id.$oid
      : jsonWf.actions_checked[0].platforms_settings_id;
    try {
      const putResponse = await client.put(
        "api/teams/v1/workflow/" + wf.workflow[indexEcommerce]._id,
        jsonWf
      );
      if (putResponse.data.access === true) {
        getWfTemplates();
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function saveWorkflow(values: any, wf: any, indexEcommerce: number) {
    if (wf.workflow && wf.workflow[indexEcommerce]) {
      //PUT WORKFLOW
      putWorkflow(values, wf, indexEcommerce);
    } else {
      //POST WORKFLOW
      let jsonWorkflow = setFieldsWf(values, wf);
      try {
        const response = await client.post(
          "api/teams/v1/workflow",
          jsonWorkflow
        );
        if (response.data.access === true) {
          getWfTemplates();
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
  function checkExistConnectorsInAndOut(wf: any) {
    let platIn = wf.platforms_input_id;
    let platOut = wf.platforms_out_id;
    let countIn = 0;
    let countOut = 0;
    let existPlatformsStartAndOut = false;
    let existTeamsConnector = false;
    if (teamsPlat) {
      existTeamsConnector = true;
    }
    connectors.forEach((element: any) => {
      if (platIn.includes(element.platforms_id)) {
        countIn++;
      }
    });
    connectors.forEach((element: any) => {
      if (platOut.includes(element.platforms_id)) {
        countOut++;
      }
    });
    if (countIn > 0 && countOut > 0) {
      if (wf.select_channels) {
        if (existTeamsConnector) {
          existPlatformsStartAndOut = true;
        } else {
          existPlatformsStartAndOut = false;
        }
      } else {
        existPlatformsStartAndOut = true;
      }
    } else {
      existPlatformsStartAndOut = false;
    }
    // setStartAndOut(!existPlatformsStartAndOut);
    startAndOut = !existPlatformsStartAndOut;

    return !existPlatformsStartAndOut;
  }

  function getCollaboratioPlatform() {
    try {
      client
        .get("api/teams/v1/platform/collaboration")
        .then((response: any) => {
          const msTeams = response.data.data.find((element: any) => {
            return element.name === "microsoft365";
          });
          platformMicrosoftTeams = msTeams;
          // setPlatformMSTeams(msTeams);
        });
    } catch (e) {
      console.log(e);
    }
  }

  function getStart(wf: any, platformsStartId: any, index: number) {
    let idEcommerce = null;
    let idErp = null;
    let idShipping = null;
    let id = null;

    platformsStartId[wf._id].map((platformsStartId: any) => {
      idEcommerce = ecommerceConn.find((element: any) => {
        return element._id === platformsStartId["key"];
      });
      idErp = erpConn.find((element: any) => {
        return element._id === platformsStartId["key"];
      });
      idShipping = shippingConn.find((element: any) => {
        return element._id === platformsStartId["key"];
      });
    });

    if (idEcommerce) {
      id = ecommerceConn[index];
    }
    if (idErp) {
      id = erpConn[0];
    }
    if (idShipping) {
      id = shippingConn[0];
    }
    if (id) {
      return id;
    }
  }

  function getTo(wf: any, platformsToId: any, index: number) {
    let idEcommerce = null;
    let idErp = null;
    let idShipping = null;
    let id = null;

    if (wf.show_select_to && platforms_to_id[wf._id]) {
      platformsToId[wf._id].map((platformsToId: any) => {
        idEcommerce = ecommerceConn.find((element: any) => {
          return element._id === platformsToId["key"];
        });
        idErp = erpConn.find((element: any) => {
          return element._id === platformsToId["key"];
        });
        idShipping = shippingConn.find((element: any) => {
          return element._id === platformsToId["key"];
        });
      });
    }

    if (idEcommerce) {
      id = ecommerceConn[index];
    }
    if (idErp) {
      id = erpConn[0];
    }
    if (idShipping) {
      id = shippingConn[0];
    }

    if (id) {
      return id;
    }
  }
  if (!authentication) {
    return (
      <Login
        onLoginSuccess={() => {
          setAuthentication(true);
        }}
      />
    );
  }

  return (
    <>
      <HeaderTab></HeaderTab>
      <Flex>
        {ecommerceConn.length > 0 &&
          (erpConn.length === 0 || shippingConn.length === 0) && (
            <Alert
              styles={{
                background: "#f29200",
                color: "white",
                fontWeight: "bold",
                textAlign: "center",
              }}
              content=" Per abilitare i workflow, collega a Semplisio le tue Apps. Per iniziare collega almeno un ecommerce e un ERP nella sezione 'Apps'."
            />
          )}
      </Flex>

      {ecommerceConn.length === 0 && (
        <div className="ms-Grid-row" style={{ marginTop: "80px" }}>
          <div
            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xs12"
            style={{ textAlign: "center" }}
          >
            <embed
              src={process.env.PUBLIC_URL + "/img_no_connectors.svg"}
            ></embed>
          </div>
          <div
            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xs12 colorBlue"
            style={{ textAlign: "center" }}
          >
            <h3 className="colorBlue">
              Per iniziare collega a Semplisio almeno un ecommerce e un ERP
              dalla sezione Apps.
            </h3>
          </div>
        </div>
      )}

      {ecommerceConn.length > 0 &&
        ecommerceConn.map((ecommerce: any, indexEcommerce: number) => {
          return (
            <>
              <Flex
                styles={{
                  marginBottom: "5px",
                  marginTop: "40px",
                  marginLeft: "70px",
                  width: "87%",
                  background: "#208ead",

                  paddingBottom: "5px",
                }}
              >
                <span
                  style={{
                    fontWeight: "bold",

                    padding: "5px",
                    color: "white",
                  }}
                >
                  {" "}
                  Il canale di vendita n. {indexEcommerce + 1} è :{" "}
                  {ecommerce.name}
                </span>
              </Flex>

              <Flex
                hAlign="center"
                padding="padding.medium"
                style={{
                  paddingTop: "0px",
                  marginBottom: "80px",
                  flexWrap: "wrap",
                }}
              >
                {templateWf.length > 0 &&
                  templateWf.map((wf: any, index: number) => {
                    return (
                      <FlexItem styles={{ marginRight: "20px" }} key={wf._id}>
                        {
                          <Formik
                            enableReinitialize
                            initialValues={
                              wf.workflow && wf.workflow[indexEcommerce]
                                ? {
                                  wf_name: wf.key_name,
                                  teams:
                                    wf.actions_templates &&
                                      wf.actions_templates[0] &&
                                      wf.actions_templates[0].select_teams
                                      ? wf.workflow[indexEcommerce]
                                        .actions_checked[0].teams
                                      : "",
                                  channel:
                                    wf.actions_templates &&
                                      wf.actions_templates[0] &&
                                      wf.actions_templates[0].select_channels
                                      ? wf.workflow[indexEcommerce]
                                        .actions_checked[0].channel
                                      : "",
                                  platform_start_id:
                                    wf.workflow[indexEcommerce]
                                      .platform_start_id,
                                  platform_to_id:
                                    wf.workflow[indexEcommerce]
                                      .actions_checked[0]
                                      .platforms_settings_id &&
                                      wf.workflow[indexEcommerce]
                                        .actions_checked[0]
                                        .platforms_settings_id.$oid
                                      ? wf.workflow[indexEcommerce]
                                        .actions_checked[0]
                                        .platforms_settings_id.$oid
                                      : null,
                                  enabled: wf.enabled,
                                  show_actions_teams_channel: wf.workflow[
                                    indexEcommerce
                                  ].actions_checked[0].action_card
                                    ? wf.workflow[indexEcommerce]
                                      .actions_checked[0].action_card[0]
                                      .show_actions_teams_channel
                                    : false,
                                }
                                : {
                                  wf_name: wf.key_name,
                                  teams: "",
                                  show_actions_teams_channel: false,
                                  platform_start_id: getStart(
                                    wf,
                                    platforms_start_id,
                                    indexEcommerce
                                  )._id,
                                  platforms_settings_id: getTo(
                                    wf,
                                    platforms_to_id,
                                    indexEcommerce
                                  )
                                    ? getTo(
                                      wf,
                                      platforms_to_id,
                                      indexEcommerce
                                    )._id
                                    : null,
                                }
                            }
                            onSubmit={(values) => {
                              saveWorkflow(values, wf, indexEcommerce);
                            }}
                          >
                            {({
                              values,
                              setFieldValue,
                              submitForm,
                              initialValues,
                            }) => (
                              <Card
                                disabled={checkExistConnectorsInAndOut(wf)}
                                aria-roledescription="card with image and text"
                                styles={{
                                  minWidth: "340px",
                                  minHeight: "450px",
                                  marginRight: "15px",
                                  marginTop: "20px",
                                }}
                              >
                                <Card.Header>
                                  <div className="triangle">
                                    <div className="contentTriangle">
                                      {index + 1}
                                    </div>
                                  </div>
                                </Card.Header>
                                <Card.Body fitted>
                                  <Form>
                                    <Flex column gap="gap.small">
                                      <Flex.Item size="size.quarter">
                                        <>
                                          <Text
                                            weight="bold"
                                            align="center"
                                            style={{
                                              marginBottom: "20px",
                                              minHeight: "40px",
                                            }}
                                            content={
                                              loadData[wf.key_name]
                                                ? loadData[wf.key_name].replace(
                                                  "{To}",
                                                  getTo(
                                                    wf,
                                                    platforms_to_id,
                                                    indexEcommerce
                                                  )
                                                    ? getTo(
                                                      wf,
                                                      platforms_to_id,
                                                      indexEcommerce
                                                    ).name
                                                    : null
                                                )
                                                : wf.key_name
                                            }
                                          />

                                          <Text weight="light" align="center">
                                            {<b>Dettagli:</b> +
                                              loadData[wf.key_description]
                                              ? loadData[wf.key_description]
                                                .replace(
                                                  "{From}",
                                                  getStart(
                                                    wf,
                                                    platforms_start_id,
                                                    indexEcommerce
                                                  )
                                                    ? getStart(
                                                      wf,
                                                      platforms_start_id,
                                                      indexEcommerce
                                                    ).name
                                                    : " "
                                                )
                                                .replace(
                                                  "{To}",
                                                  getTo(
                                                    wf,
                                                    platforms_to_id,
                                                    indexEcommerce
                                                  )
                                                    ? getTo(
                                                      wf,
                                                      platforms_to_id,
                                                      indexEcommerce
                                                    ).name
                                                    : " "
                                                )
                                              : wf.key_description}
                                          </Text>

                                          {platforms_start_id[wf._id] && (
                                            <Dropdown
                                              style={{ display: "none" }}
                                              items={platforms_start_id[wf._id]}
                                              disabled={checkExistConnectorsInAndOut(
                                                wf
                                              )}
                                              defaultValue={platforms_start_id[
                                                wf._id
                                              ].find((element: any) => {
                                                return (
                                                  element.key ===
                                                  initialValues.platform_start_id
                                                );
                                              })}
                                              onChange={(event, data: any) => {
                                                setFieldValue(
                                                  "platform_start_id",
                                                  data && data.value
                                                    ? data.value.key
                                                    : null
                                                );
                                                if (wf.workflow) {
                                                  putWorkflow(
                                                    values,
                                                    wf,
                                                    indexEcommerce
                                                  );
                                                }
                                              }}
                                              placeholder="Seleziona la piattaforma di start"
                                            />
                                          )}

                                          {wf.show_select_to &&
                                            platforms_to_id[wf._id] && (
                                              <Dropdown
                                                style={{ display: "none" }}
                                                items={platforms_to_id[wf._id]}
                                                disabled={checkExistConnectorsInAndOut(
                                                  wf
                                                )}
                                                defaultValue={platforms_to_id[
                                                  wf._id
                                                ].find((element: any) => {
                                                  return (
                                                    element.key ===
                                                    initialValues.platform_to_id
                                                  );
                                                })}
                                                onChange={(
                                                  event,
                                                  data: any
                                                ) => {
                                                  setFieldValue(
                                                    "platforms_settings_id",
                                                    data && data.value
                                                      ? data.value.key
                                                      : null
                                                  );
                                                  if (wf.workflow) {
                                                    putWorkflow(
                                                      values,
                                                      wf,
                                                      indexEcommerce
                                                    );
                                                  }
                                                }}
                                                placeholder="Seleziona la piattaforma di arrivo"
                                              />
                                            )}
                                          {teams.length > 0 &&
                                            wf.actions_templates &&
                                            wf.actions_templates[0] &&
                                            wf.actions_templates[0]
                                              .select_teams && (
                                              <Dropdown
                                                disabled={checkExistConnectorsInAndOut(
                                                  wf
                                                )}
                                                items={teams}
                                                defaultValue={teams.find(
                                                  (element: any) => {
                                                    return (
                                                      element.key ===
                                                      initialValues.teams
                                                    );
                                                  }
                                                )}
                                                onChange={(
                                                  event,
                                                  data: any
                                                ) => {
                                                  setFieldValue(
                                                    "teams",
                                                    data && data.value
                                                      ? data.value.key
                                                      : null
                                                  );
                                                  onChangeHandler(event, data);
                                                }}
                                                placeholder="Seleziona il team da informare"
                                                noResultsMessage="Non troviamo nessun Team con questo nome"
                                                getA11ySelectionMessage={{
                                                  onAdd: (item) =>
                                                    `${item} has been selected.`,
                                                }}
                                              />
                                            )}
                                          {channels[values.teams] &&
                                            wf.actions_templates &&
                                            wf.actions_templates[0] &&
                                            wf.actions_templates[0]
                                              .select_channels && (
                                              <Dropdown
                                                disabled={checkExistConnectorsInAndOut(
                                                  wf
                                                )}
                                                items={channels[values.teams]}
                                                defaultValue={channels[
                                                  values.teams
                                                ].find((element: any) => {
                                                  return (
                                                    element.key ===
                                                    initialValues.channel
                                                  );
                                                })}
                                                onChange={(
                                                  event,
                                                  data: any
                                                ) => {
                                                  setFieldValue(
                                                    "channel",
                                                    data && data.value
                                                      ? data.value.key
                                                      : null
                                                  );
                                                  if (
                                                    wf.workflow &&
                                                    wf.workflow[indexEcommerce]
                                                  ) {
                                                    putWorkflow(
                                                      values,
                                                      wf,
                                                      indexEcommerce,
                                                      data.value.key
                                                    );
                                                  }
                                                }}
                                                placeholder="Seleziona il canale del team da informare"
                                              />
                                            )}
                                        </>
                                      </Flex.Item>

                                      {loadData[wf.key_name + "_info"] && (
                                        <Text
                                          weight="light"
                                          style={{
                                            marginBottom: "20px",
                                            minHeight: "40px",
                                          }}
                                          content={
                                            loadData[wf.key_name + "_info"]
                                          }
                                        />
                                      )}

                                      {/* <button type="submit">test</button> */}
                                      {wf.actions_templates &&
                                        wf.actions_templates[0] &&
                                        wf.actions_templates[0].action_card &&
                                        wf.actions_templates[0].action_card[0]
                                          .show_actions_teams_channel_orders && (
                                          <Checkbox
                                            disabled={checkExistConnectorsInAndOut(
                                              wf
                                            )}
                                            onChange={(event, data: any) => {
                                              setFieldValue(
                                                "show_actions_teams_channel",
                                                data?.checked ? true : false
                                              );
                                              if (
                                                wf.workflow &&
                                                wf.workflow[indexEcommerce]
                                              ) {
                                                putWorkflow(
                                                  values,
                                                  wf,
                                                  indexEcommerce,
                                                  null,
                                                  data?.checked ? true : false
                                                );
                                              }
                                            }}
                                            defaultChecked={
                                              initialValues.show_actions_teams_channel
                                                ? initialValues.show_actions_teams_channel
                                                : false
                                            }
                                            label={
                                              loadData["flag"]
                                                ? loadData["flag"].replace(
                                                  "{To}",
                                                  getTo(
                                                    wf,
                                                    platforms_to_id,
                                                    indexEcommerce
                                                  )
                                                    ? getTo(
                                                      wf,
                                                      platforms_to_id,
                                                      indexEcommerce
                                                    ).name
                                                    : "gestionale"
                                                )
                                                : "gestionale"
                                            }
                                          />
                                        )}
                                      <Checkbox
                                        disabled={checkExistConnectorsInAndOut(
                                          wf
                                        )}
                                        styles={{
                                          position: "absolute",
                                          bottom: "20",
                                          right: "10",
                                        }}
                                        defaultChecked={
                                          initialValues.enabled
                                            ? initialValues.enabled
                                            : false
                                        }
                                        onChange={(event, data) => {
                                          setFieldValue(
                                            "enabled",
                                            data?.checked ? data.checked : false
                                          );
                                          submitForm();
                                        }}
                                        toggle
                                      />
                                    </Flex>
                                  </Form>
                                </Card.Body>
                              </Card>
                            )}
                          </Formik>
                        }
                      </FlexItem>
                    );
                  })}
              </Flex>
            </>
          );
        })}
    </>
  );
};

export default Workflow;
