import { useState } from "react";
import {
  Form,
  FormButton,
  Input,
  Label,
  Alert,
  Flex,
} from "@fluentui/react-northstar";
import {
  ExclamationTriangleIcon,
  LockIcon,
  PopupIcon,
  UserBlurIcon,
} from "@fluentui/react-icons-northstar";
import "./Login.css";
import { client } from "../utils/client";

function Login(props: { onLoginSuccess: () => void }) {
  const [{ email, password }, setFormData] = useState({
    email: "",
    password: "",
  });

  const [alert, setAlert] = useState(false);

  const onChange = (name: any, value: any) => {
    setFormData((oldFormData) => ({
      ...oldFormData,
      [name]: value,
    }));
  };
  async function handleSubmit() {
    try {
      const response = await client.post(`api/teams/v1/login`, {
        email,
        password,
      });
      if (response.data.data.activation_token) {
        localStorage.setItem(
          "activation_token",
          response.data.data.activation_token
        );
        props.onLoginSuccess();
      }
    } catch (e) {
      console.log(e);
      setAlert(true);
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Flex>
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xs12">
              <div
                className="ms-Grid-col ms-sm12 ms-lgoffset2 ms-md4 ms-lg8 ms-xs12"
                style={{ alignContent: "center" }}
              >
                <div
                  className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xs12 textCenter"
                  style={{ marginTop: "15px", textAlign: "center" }}
                >
                  <img
                    style={{ fontWeight: "50%" }}
                    src={process.env.PUBLIC_URL + "/" + "semplisioLogo1.png"}
                  ></img>
                </div>
                <div
                  className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-lgoffset3 ms-xs12 textCenter"
                  style={{ marginTop: "15px", textAlign: "center" }}
                >
                  <p>
                    Gestisci l’ecommerce. Approva i processi. Informa azioni.
                    Tutto in Microsoft Teams
                  </p>
                </div>
                <div
                  className="ms-Grid-col ms-sm12 ms-md12 ms-lg8 ms-lgoffset2 ms-xs12 textCenter"
                  style={{
                    marginTop: "15px",
                    backgroundColor: "#208ead",
                    paddingRight: "20px",
                    paddingLeft: "20px",
                    paddingBottom: "40px",
                  }}
                >
                  <div className="ms-Grid-row">
                    <div
                      className="ms-Grid-col ms-sm12 ms-md12 ms-lg12  ms-xs12 textCenter"
                      style={{ marginTop: "15px", color: "white" }}
                    >
                      <h3>Effettua il login a Semplisio</h3>
                    </div>
                  </div>
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xs12">
                      <Input
                        label={
                          <Label
                            content="Username"
                            icon={<UserBlurIcon />}
                            iconPosition="start"
                            style={{
                              backgroundColor: "#b6cf58",
                              color: "white",
                              height: "32px",
                              width: "100px",
                              borderTopRightRadius: "0",
                              borderBottomRightRadius: "0",
                            }}
                          />
                        }
                        labelPosition="inline"
                        name="email"
                        id="email"
                        key="email"
                        onChange={(e) =>
                          onChange(
                            (e.target as HTMLTextAreaElement).name,
                            (e.target as HTMLTextAreaElement).value
                          )
                        }
                        required
                        showSuccessIndicator={false}
                        style={{
                          borderBottomLeftRadius: "0",
                          borderTopLeftRadius: "0",
                        }}
                      />
                    </div>
                  </div>
                  <div className="ms-Grid-row">
                    <div
                      className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xs12"
                      style={{ marginTop: "20px" }}
                    >
                      <Input
                        label={
                          <Label
                            content="Password"
                            icon={<LockIcon />}
                            iconPosition="start"
                            style={{
                              backgroundColor: "#b6cf58",
                              color: "white",
                              height: "32px",
                              width: "100px",
                              borderTopRightRadius: "0",
                              borderBottomRightRadius: "0",
                            }}
                          />
                        }
                        labelPosition="inline"
                        name="password"
                        id="password"
                        key="password"
                        type="password"
                        onChange={(e) =>
                          onChange(
                            (e.target as HTMLTextAreaElement).name,
                            (e.target as HTMLTextAreaElement).value
                          )
                        }
                        required
                        showSuccessIndicator={false}
                        style={{
                          borderBottomLeftRadius: "0",
                          borderTopLeftRadius: "0",
                        }}
                      />
                    </div>
                  </div>
                  <div className="ms-Grid-row" style={{ marginTop: "40px" }}>
                    <div
                      className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xs12 "
                      style={{ textAlign: "right" }}
                    >
                      <FormButton
                        content="Login"
                        style={{
                          backgroundColor: "#b6cf58",
                          color: "white",
                          width: "30%",
                        }}
                      />
                    </div>
                  </div>
                  <div className="ms-Grid-row" style={{ marginTop: "20px" }}>
                    <div
                      className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xs12 "
                      style={{ textAlign: "center" }}
                    >
                      <span style={{ color: "white" }}>
                        Hai dimenticato l'username o la password?
                      </span>
                      <a
                        style={{ color: "#b6cf58" }}
                        href="https://panel.semplisio.it/recovery-password"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Clicca qui <PopupIcon></PopupIcon>
                      </a>
                    </div>
                  </div>
                  <div className="ms-Grid-row " style={{ marginTop: "10px" }}>
                    <div
                      className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xs12 "
                      style={{ textAlign: "center" }}
                    >
                      <span style={{ color: "white" }}>
                        Non sei ancora registrato?
                      </span>
                      <a
                        style={{ color: "#b6cf58" }}
                        href="https://www.semplisio.it/acquista/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Scopri Semplisio <PopupIcon></PopupIcon>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-lgoffset3 ms-xs12 textCenter"
                  style={{ marginTop: "15px", textAlign: "center" }}
                >
                  <p>
                    È necessario avere attivato un abbonamento Semplisio per il
                    funzionamento dell’app
                  </p>
                </div>
                <div className="ms-Grid-row">
                  <div
                    className="ms-Grid-col ms-sm12 ms-md12 ms-lg8 ms-lgoffset2 ms-xs12"
                    style={{ marginTop: "20px", marginBottom: "40px" }}
                  >
                    {alert ? (
                      <Alert
                        danger
                        icon={<ExclamationTriangleIcon />}
                        content="Username o password errati. Ti preghiamo di riprovare"
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Flex>
    </Form>
  );
}
export default Login;
