import { Button } from "@fluentui/react-northstar";
import { TeamsUserCredential } from "@microsoft/teamsfx";
import { useState } from "react";
import { useData } from "../sample/lib/useData";

function logout() {
  localStorage.clear();
  window.location.reload();
}
const HeaderTab = () => {
  const userName = "";

  return (
    <div className="ms-Grid-row">
      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xs12 backgroundColorBlue colorWhite">
        <div
          className="ms-Grid-col ms-sm12 ms-md3 ms-lg3 ms-xs12 textCenter"
          style={{ marginTop: "15px", paddingBottom: "20px" }}
        >
          <img
            style={{ fontWeight: "50%", width: "-webkit-fill-available" }}
            src={process.env.PUBLIC_URL + "/" + "logoregistratobianco.png"}
          />
        </div>

        <div
          className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 ms-lgoffset1 ms-xs12 textCenter mobileMarginTop0"
          style={{ marginTop: "40px", paddingBottom: "20px" }}
        >
          <span className="colorWhite">
            {" "}
            Salve{" "}
            <span className="colorGreen">
              {localStorage.getItem("userName")}
            </span>
            , ora puoi gestire il tuo ecommerce da TEAMS{" "}
          </span>
        </div>
        <div
          className="ms-Grid-col ms-sm2  ms-md2 ms-lg2  ms-xs2 ms-xsoffset8 textCenter mobileMarginTop0"
          style={{ marginTop: "40px", paddingBottom: "20px" }}
        >
          <Button
            onClick={logout}
            type="button"
            content="Logout"
            style={{ backgroundColor: "#208ead", color: "white" }}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderTab;
