import React from "react";
import "./App.css";
import * as microsoftTeams from "@microsoft/teams-js";

/**
 * The 'Config' component is used to display your group tabs
 * user configuration options.  Here you will allow the user to
 * make their choices and once they are done you will need to validate
 * their choices and communicate that to Teams to enable the save button.
 */
class TabConfig extends React.Component {
  render() {
    // Initialize the Microsoft Teams SDK
    microsoftTeams.initialize();

    /**
     * When the user clicks "Save", save the url for your configured tab.
     * This allows for the addition of query string parameters based on
     * the settings selected by the user.
     */
    microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
      const baseUrl = `https://${window.location.hostname}:${window.location.port}`;
      microsoftTeams.settings.setSettings({
        suggestedDisplayName: "Semplisio",
        entityId: "Semplisio",
        contentUrl: baseUrl + "/index.html#/tab",
        websiteUrl: baseUrl + "/index.html#/tab",
      });
      saveEvent.notifySuccess();
    });

    /**
     * After verifying that the settings for your tab are correctly
     * filled in by the user you need to set the state of the dialog
     * to be valid.  This will enable the save button in the configuration
     * dialog.
     */
    microsoftTeams.settings.setValidityState(true);

    return (
      <div style={{}}>
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <img
            alt="logo"
            style={{ height: "80px", width: "286px" }}
            src={process.env.PUBLIC_URL + "/" + "logo_registrato.png"}
          />
        </div>
        <div
          style={{ marginTop: "50px", textAlign: "left", paddingLeft: "30px" }}
        >
          Semplisio gestisce tutto l’ecommerce della tua azienda in TEAMS:
          <br></br>- Attiva i workflow nell’app <br></br>- Aggiungi Semplisio
          nel team per la corretta esecuzione dei workflow <br></br>- Inizia a
          vendere <br></br>
          Hai bisogno di un abbonamento Semplisio per iniziare.<br></br>
          Se non ne hai ancora uno, clicca qui{" "}
          <a
            href="http://www.semplisio.it/acquista/"
            target="_blank"
            rel="noreferrer"
          >
            www.semplisio.it/acquista
          </a>
          <br></br>
          Clicca su Salva per aggiungere Semplisio nel Team
        </div>
      </div>
    );
  }
}

export default TabConfig;
